@import url('https://fonts.googleapis.com/css2?family=Work+Sans&display=swap');

.received {
  display:grid;
  grid-template-columns: 1fr 9fr;
}

section {
  width: 50%;
  margin: auto;
  background-color: white;
  padding: 5px 20px;
  border-radius: 15px;
}

/* .mainContainer {
  display: flex;
  height: 500px;
  flex-direction: column;
} */

main {
  display: flex;
  flex-direction: column;
  /* justify-content: flex-end; */
  overflow: auto;
  height: 70vh;
  /* flex: 1 1 auto; */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

main::-webkit-scrollbar {
  display: none;
}

form {
  width: 50%;
  margin: auto;
}

.left img {
  border-radius: 50%;
  width: 70%;
  margin-bottom: 10px;
  margin-top: auto;
}

.left {
  display: flex;
  flex-direction: column-reverse;
}

.userName {
  font-size: 1.5vw;
  color: rgb(178, 178, 178);
  margin-bottom: 2px;
}

.right {
  display: flex;
  flex-direction: column;
}

.textContent {
  font-size: 3vw;
  padding: 0.5vw 0.7vw;
  border-radius: 20px;
  margin: 5px auto 0px 5px;
  background-color: rgb(228, 227, 233);
}

.message {
  margin: 10px 0px;
}

body {
  background-color: black;
}

header {
  text-align: center;
  padding-bottom: 5px;
  margin-bottom: 10px;
}

h1 {
  color: white;
  margin: 0px;
  padding-top: 15px;
  padding-bottom: 0px;
  font-size: 3vw;
}

h2 {
  color: rgb(2, 148, 226);
  text-decoration: underline;
  text-decoration-color: rgb(2, 148, 226);
  margin: 0px;
  padding-bottom: 15px;
  font-size: 1vw;
}

form {
  margin: 0px;
  width: 100%;
  font-size: 2vw;
}

input {
  box-sizing: border-box;
  width: 100%;
  border-radius: 15px;
  padding: 5px;
  margin-top: 20px;
  margin-bottom: 14px;
  height: 50px;
  font-size: 1.2vw;
}

.sent {
  display: flex;
  flex-direction: row-reverse;
}

.bubbleSent {
  background-color: rgb(54,130,243);
  color: white;
}

button {
  border-radius: 15px;
  padding: 10px;
  font-size: 1vw;
  margin: auto;
}

* {
  font-family: 'Work Sans', sans-serif;
}

@media only screen and (max-width: 600px) {
  section {
    width: 100%;
    box-sizing: border-box;
  }
  main {
    height: 65vh;
  }

  .userName {
    font-size: 15px;
  }

  .textContent {
    font-size: 20px;
    padding: 5px 10px;
  }

  .left img {
    width: 30px;
    margin-bottom: 3px;
  }

  input {
    padding: 5px;
    margin-top: 20px;
    margin-bottom: 14px;
    font-size: 20px;
  }

  header {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
  }

  h1 {
    font-size: 30px;
    text-align: left;
    padding-left: 25px;
  }

  h2 {
    font-size: 15px;
    text-align: left;
    padding-bottom: 8px;
  }

  a {
    margin-top: auto;
  }

  button {
    border-radius: 15px;
    font-size: 15px;
    padding: 5px;
    margin-top: 15px;
  }
}